import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-12 col-sm-12 col-xl" }
const _hoisted_2 = { class: "card h-100" }
const _hoisted_3 = { class: "\r\n          card-body\r\n          d-flex\r\n          justify-content-center\r\n          text-center\r\n          flex-column\r\n          p-8\r\n        " }
const _hoisted_4 = {
  href: "#",
  class: "text-gray-800 text-hover-primary d-flex flex-column"
}
const _hoisted_5 = { class: "symbol symbol-60px mb-6" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "fs-5 fw-bolder mb-2" }
const _hoisted_8 = { class: "fs-7 fw-bold text-gray-400 mt-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: `media/svg/files/${_ctx.fileType}.svg`,
              alt: ""
            }, null, 8, _hoisted_6)
          ]),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.fileTitle), 1)
        ]),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.createdAt), 1)
      ])
    ])
  ]))
}